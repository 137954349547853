import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
  LinearProgress,
  Divider
} from '@mui/material';
import { Check, Error as ErrorIcon, QrCode2 } from '@mui/icons-material';
import { QRCodeCanvas } from 'qrcode.react';

const ConnectionModal = ({ open, onClose, roomId, phone }) => {
  // Set initial countdown to 120 seconds (2 minutes)
  const [countdown, setCountdown] = useState(120);
  const [status, setStatus] = useState('initializing'); // initializing, qrcode, success, failed
  const [qrCodeData, setQrCodeData] = useState('');
  const [loginCode, setLoginCode] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  // Function to fetch QR code
  const fetchQrCode = useCallback(async () => {
	if (!roomId) return;
	
	try {
	  const token = localStorage.getItem('authToken');
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/connect/qrcode/${roomId}`, {
		headers: {
		  'Authorization': `Bearer ${token}`
		}
	  });
	  
	  const data = await response.json();
	  
	  if (data.found) {
		console.log("Received QR data:", data);
		
		// Extract login code from the message; removed unnecessary escape character for hyphen.
		const codeMatch = data.loginCode?.match(/code on your phone to log in:\s*\*\*([A-Z0-9-]+)\*\*/);
		const extractedLoginCode = codeMatch ? codeMatch[1] : '';
		setLoginCode(extractedLoginCode);
		
		// Set QR code data and update status
		setQrCodeData(data.qrData || '');
		setStatus('qrcode');
	  } else {
		// If QR code not found yet, keep checking (we're still in initializing phase)
		setTimeout(fetchQrCode, 2000);
	  }
	} catch (err) {
	  console.error('Error fetching QR code:', err);
	  setError('Failed to fetch QR code. Please try again.');
	  setStatus('failed');
	}
  }, [roomId]);

  // Function to check login status
  const checkLoginStatus = useCallback(async () => {
	if (!roomId || !phone || status !== 'qrcode') return;
	
	try {
	  const token = localStorage.getItem('authToken');
	  const encodedPhone = encodeURIComponent(phone);
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/connect/check/${roomId}/${encodedPhone}`, {
		headers: {
		  'Authorization': `Bearer ${token}`
		}
	  });
	  
	  const data = await response.json();
	  
	  if (data.found) {
		setSuccessMessage(data.message);
		setStatus('success');
	  }
	} catch (err) {
	  console.error('Error checking login status:', err);
	}
  }, [roomId, phone, status]);

  // Initialize the connection process with a 10-second delay
  useEffect(() => {
	if (open && roomId) {
	  setStatus('initializing');
	  setCountdown(120);
	  setQrCodeData('');
	  setLoginCode('');
	  setSuccessMessage('');
	  setError('');
	  
	  // 10-second delay before fetching QR code
	  const delayTimer = setTimeout(() => {
		fetchQrCode();
	  }, 10000);
	  
	  return () => clearTimeout(delayTimer);
	}
  }, [open, roomId, fetchQrCode]);

  // Handle countdown timer and poll for login status when QR code is visible
  useEffect(() => {
	if (status !== 'qrcode' || !open) return;
	
	// Countdown timer
	const timer = setInterval(() => {
	  setCountdown(prev => {
		if (prev <= 1) {
		  setStatus('failed');
		  setError('Connection timed out. Please try again.');
		  return 0;
		}
		return prev - 1;
	  });
	}, 1000);
	
	// Poll for login success every 5 seconds
	const pollTimer = setInterval(() => {
	  checkLoginStatus();
	}, 5000);
	
	return () => {
	  clearInterval(timer);
	  clearInterval(pollTimer);
	};
  }, [status, open, checkLoginStatus]);

  return (
	<Dialog 
	  open={open} 
	  onClose={status === 'qrcode' ? null : onClose} // Prevent closing during active connection
	  maxWidth="sm"
	  fullWidth
	  PaperProps={{ 
		className: "connection-modal",
		sx: {
		  borderRadius: '12px',
		  overflow: 'hidden'
		}
	  }}
	>
	  <DialogTitle className={`connection-modal-title ${status}`}>
		<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
		  {status === 'initializing' && (
			<>
			  <CircularProgress size={20} color="inherit" />
			  <Typography variant="h6">Initializing Connection...</Typography>
			</>
		  )}
		  
		  {status === 'qrcode' && (
			<>
			  <QrCode2 />
			  <Typography variant="h6">Connect WhatsApp</Typography>
			</>
		  )}
		  
		  {status === 'success' && (
			<>
			  <Check />
			  <Typography variant="h6">Connection Successful</Typography>
			</>
		  )}
		  
		  {status === 'failed' && (
			<>
			  <ErrorIcon />
			  <Typography variant="h6">Connection Failed</Typography>
			</>
		  )}
		</Box>
	  </DialogTitle>
	  
	  <DialogContent className="connection-modal-content">
		{status === 'initializing' && (
		  <Box sx={{ 
			display: 'flex', 
			flexDirection: 'column', 
			alignItems: 'center',
			justifyContent: 'center',
			py: 4
		  }}>
			<CircularProgress size={40} thickness={4} />
			<Typography sx={{ mt: 2 }}>
			  Preparing connection for {phone}...
			</Typography>
		  </Box>
		)}
		
		{status === 'qrcode' && (
		  <Box sx={{ py: 2 }}>
			<Box className="qr-message-container">
			  <Box className="qr-code-box">
				<Typography variant="body2" color="text.secondary" gutterBottom>
				  Open WhatsApp on your phone and scan this QR code:
				</Typography>
				<Box 
				  sx={{ 
					display: 'flex', 
					flexDirection: 'column',
					alignItems: 'center',
					backgroundColor: 'white',
					p: 3,
					borderRadius: '8px',
					border: '1px solid #eee'
				  }}
				>
				  <QRCodeCanvas value={qrCodeData || loginCode} size={200} />
				</Box>
			  </Box>
			  
			  <Divider sx={{ my: 2 }} />
			  
			  <Box className="code-box">
				<Typography variant="body1" gutterBottom>
				  Or enter this code on your phone:
				</Typography>
				
				<Typography variant="h4" component="div" align="center" sx={{ 
				  fontWeight: 'bold',
				  my: 2,
				  letterSpacing: '2px',
				  fontFamily: 'monospace'
				}}>
				  {loginCode}
				</Typography>
			  </Box>
			  
			  <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
				This code will expire in <span style={{ fontWeight: 'bold' }}>{countdown} seconds</span>
			  </Typography>
			</Box>
			
			<Box sx={{ mt: 3 }}>
			  <LinearProgress 
				variant="determinate" 
				value={(countdown / 120) * 100} 
				color="primary"
				sx={{ 
				  height: 8, 
				  borderRadius: 4,
				  '& .MuiLinearProgress-bar': {
					borderRadius: 4
				  }
				}}
			  />
			</Box>
		  </Box>
		)}
		
		{status === 'success' && (
		  <Box sx={{ 
			py: 3, 
			textAlign: 'center', 
			display: 'flex', 
			flexDirection: 'column', 
			alignItems: 'center' 
		  }}>
			<Box sx={{ 
			  width: 60, 
			  height: 60, 
			  borderRadius: '50%', 
			  bgcolor: 'success.light',
			  color: 'success.main',
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  mb: 2
			}}>
			  <Check sx={{ fontSize: 36 }} />
			</Box>
			
			<Typography variant="h6" gutterBottom>
			  WhatsApp Connected Successfully
			</Typography>
			
			<Typography variant="body2" color="text.secondary">
			  {successMessage || `Successfully logged in as ${phone}`}
			</Typography>
		  </Box>
		)}
		
		{status === 'failed' && (
		  <Box sx={{ 
			py: 3, 
			textAlign: 'center', 
			display: 'flex', 
			flexDirection: 'column', 
			alignItems: 'center' 
		  }}>
			<Box sx={{ 
			  width: 60, 
			  height: 60, 
			  borderRadius: '50%', 
			  bgcolor: 'error.light',
			  color: 'error.main',
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  mb: 2
			}}>
			  <ErrorIcon sx={{ fontSize: 36 }} />
			</Box>
			
			<Typography variant="h6" gutterBottom>
			  Connection Failed
			</Typography>
			
			<Typography variant="body2" color="text.secondary">
			  {error || "Failed to connect WhatsApp. Please try again."}
			</Typography>
		  </Box>
		)}
	  </DialogContent>
	  
	  <DialogActions sx={{ p: 2, borderTop: '1px solid rgba(0,0,0,0.1)' }}>
		{(status === 'success' || status === 'failed') && (
		  <Button 
			onClick={onClose} 
			variant="contained" 
			color={status === 'success' ? 'primary' : 'error'}
			sx={{ px: 3 }}
		  >
			{status === 'success' ? 'Done' : 'Close'}
		  </Button>
		)}
		
		{status === 'qrcode' && (
		  <Button 
			onClick={onClose} 
			color="inherit"
			sx={{ color: 'text.secondary' }}
		  >
			Cancel
		  </Button>
		)}
	  </DialogActions>
	</Dialog>
  );
};

export default ConnectionModal;
