import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider, useUser } from './UserContext';
import Registration from './components/Registration/Registration';
import Dashboard from './components/Dashboard/Dashboard';

function RoutesBasedOnAuth() {
  const { isRegistered } = useUser();

  return (
    <Routes>
      {isRegistered ? (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Registration />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <UserProvider>
        <RoutesBasedOnAuth />
      </UserProvider>
    </Router>
  );
}

export default App;
