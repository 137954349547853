import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isRegistered, setIsRegistered] = useState(false);
  const navigate = useNavigate();

  // On mount, check sessionStorage for an auth token
  useEffect(() => {
	const token = sessionStorage.getItem('authToken');
	if (token) {
	  setIsRegistered(true);
	}
  }, []);

  const loginUser = () => {
	// Save a token (or flag) in sessionStorage
	sessionStorage.setItem('authToken', 'true');
	setIsRegistered(true);
  };

  const logoutUser = useCallback((reason) => {
	sessionStorage.removeItem('authToken');
	setIsRegistered(false);
	if (reason === 'inactivity') {
	  navigate('/'); // Redirect to the registration page if desired
	}
  }, [navigate]);

  // Handle auto logout after a specified period of inactivity
  useEffect(() => {
	let logoutTimer;
	const resetTimer = () => {
	  clearTimeout(logoutTimer);
	  logoutTimer = setTimeout(() => {
		logoutUser('inactivity');
		console.log('You have been logged out due to inactivity');
	  }, 10 * 60 * 1000); // 10 minutes
	};

	window.addEventListener('mousemove', resetTimer);
	window.addEventListener('keydown', resetTimer);
	resetTimer();  // Initialize timer on component mount

	return () => {
	  clearTimeout(logoutTimer);
	  window.removeEventListener('mousemove', resetTimer);
	  window.removeEventListener('keydown', resetTimer);
	};
  }, [logoutUser]);

  return (
	<UserContext.Provider value={{ isRegistered, loginUser, logoutUser }}>
	  {children}
	</UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
