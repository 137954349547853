import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Tooltip,
  Fade,
  Menu,
  MenuItem,
  Alert,
  InputAdornment,
  Container,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Paper
} from '@mui/material';
import {
  Add,
  CheckCircle,
  Warning,
  Search,
  ArrowUpward,
  ArrowDownward,
  Edit,
  FilterList,
  GetApp,
  Logout,
  ConnectWithoutContact,
  LinkOff,
  Refresh
} from '@mui/icons-material';
import './Dashboard.css';
import ConnectionModal from './ConnectionModal';

const Dashboard = () => {
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('bot_name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [connectingBotId, setConnectingBotId] = useState(null);
  const [processingBotId, setProcessingBotId] = useState(null);
  const [processingAction, setProcessingAction] = useState(null);
  
  // Connection modal state
  const [connectionModalOpen, setConnectionModalOpen] = useState(false);
  const [connectionRoomId, setConnectionRoomId] = useState(null);
  const [connectionPhone, setConnectionPhone] = useState('');

  // State for edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingBot, setEditingBot] = useState(null);
  const [editedPhone, setEditedPhone] = useState('');
  const [editedWeight, setEditedWeight] = useState('');
  const [editedStatus, setEditedStatus] = useState('');

  // Fetch bots from API
  const fetchBots = async () => {
	setLoading(true);
	const token = localStorage.getItem('authToken');
	try {
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/bots`, {
		method: 'GET',
		headers: {
		  'Authorization': `Bearer ${token}`,
		  'Content-Type': 'application/json'
		}
	  });

	  if (!response.ok) {
		throw new Error('Failed to fetch bots');
	  }

	  const data = await response.json();
	  setBots(data.bots || []);
	  setError('');
	} catch (err) {
	  console.error('Error fetching bots:', err);
	  setError('Error fetching agents. Please refresh or try again later.');
	} finally {
	  setLoading(false);
	}
  };

  useEffect(() => {
	fetchBots();
	const intervalId = setInterval(fetchBots, 5 * 60 * 1000);
	return () => clearInterval(intervalId);
  }, []);

  // Sorting
  const handleSort = (field) => {
	if (sortField === field) {
	  setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
	} else {
	  setSortField(field);
	  setSortDirection('asc');
	}
  };

  // Filter bots based on selected filter
  const filteredBots = useMemo(() => {
	let filtered = [...bots];
	if (selectedFilter !== 'all') {
	  filtered = filtered.filter(bot => bot.status === selectedFilter);
	}
	return filtered;
  }, [bots, selectedFilter]);

  // Filter and sort bots based on search query and sort criteria
  const filteredAndSortedBots = useMemo(() => {
	return filteredBots
	  .filter(bot =>
		(bot.bot_name || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
		(bot.bot_phone || '').includes(searchQuery) ||
		(bot.status || '').toLowerCase().includes(searchQuery.toLowerCase())
	  )
	  .sort((a, b) => {
		const valueA = a[sortField] || '';
		const valueB = b[sortField] || '';
		if (typeof valueA === 'string' && typeof valueB === 'string') {
		  return sortDirection === 'asc'
			? valueA.localeCompare(valueB)
			: valueB.localeCompare(valueA);
		} else {
		  return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
		}
	  });
  }, [filteredBots, searchQuery, sortField, sortDirection]);

  // Calculate total weight for percentage calculation
  const totalWeight = useMemo(() => {
	return filteredAndSortedBots.reduce((sum, bot) => sum + (bot.weight || 0), 0);
  }, [filteredAndSortedBots]);

  // Render status chip
  const getStatusChip = (status) => {
	switch (status) {
	  case 'active':
		return (
		  <span className="chip active-status">
			<CheckCircle className="chip-icon" fontSize="small" /> Active
		  </span>
		);
	  case 'idle':
		return (
		  <span className="chip idle-status">
			<Warning className="chip-icon" fontSize="small" /> Idle
		  </span>
		);
	  default:
		return (
		  <span className="chip">
			<Warning className="chip-icon" fontSize="small" /> {status || 'Unknown'}
		  </span>
		);
	}
  };

  // Edit modal handlers
  const openEditModal = (bot) => {
	setEditingBot(bot);
	setEditedPhone(bot.bot_phone || '');
	setEditedWeight(bot.weight || '');
	setEditedStatus(bot.status || 'idle');
	setEditModalOpen(true);
  };

  const closeEditModal = () => {
	setEditModalOpen(false);
	setEditingBot(null);
  };

  const handleUpdateBot = async () => {
	if (!editingBot) return;
	
	const token = localStorage.getItem('authToken');
	setError(''); // Clear any previous errors
	
	try {
	  // Format the data properly
	  const updatedData = {
		bot_phone: editedPhone.trim(), // Remove any extra spaces
		weight: editedWeight ? Number(editedWeight) : 1, // Ensure it's a number or default to 1
		status: editedStatus || 'idle' // Ensure status has a valid default
	  };
	  
	  console.log('Sending update with data:', updatedData);
	  
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/bots/${editingBot.id}`, {
		method: 'PUT',
		headers: {
		  'Authorization': `Bearer ${token}`,
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify(updatedData)
	  });
  
	  if (!response.ok) {
		const errorData = await response.json();
		throw new Error(errorData.error || 'Failed to update bot');
	  }
  
	  const result = await response.json();
	  console.log('Update successful:', result);
  
	  // Update the local state with the response data if available
	  // or fall back to our local update
	  const updatedBot = result.bot || { 
		...editingBot, 
		bot_phone: updatedData.bot_phone, 
		weight: updatedData.weight, 
		status: updatedData.status 
	  };
	  
	  setBots(bots.map(bot =>
		bot.id === editingBot.id ? updatedBot : bot
	  ));
	  
	  closeEditModal();
	} catch (err) {
	  console.error('Error updating bot:', err);
	  setError(`Error updating agent: ${err.message || 'Please try again.'}`);
	}
  };

  // Connect bot handler
  const handleConnectBot = async (botId) => {
	try {
	  const token = localStorage.getItem('authToken');
	  setConnectingBotId(botId);
	  
	  // Find the bot in our local state
	  const botToConnect = bots.find(bot => bot.id === botId);
	  if (!botToConnect) {
		throw new Error('Bot not found');
	  }
	  
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/bots/${botId}/connect`, {
		method: 'POST',
		headers: {
		  'Authorization': `Bearer ${token}`,
		  'Content-Type': 'application/json'
		}
	  });
  
	  const data = await response.json();
	  
	  if (!response.ok) {
		throw new Error(data.error || 'Connection failed');
	  }
	  
	  // Open the connection modal with the returned room ID
	  setConnectionRoomId(data.roomId);
	  setConnectionPhone(data.phone);
	  setConnectionModalOpen(true);
	  
	} catch (err) {
	  console.error('Error connecting bot:', err);
	  setError(`Error connecting agent: ${err.message}`);
	} finally {
	  setConnectingBotId(null);
	}
  };

  // Bot control handler for disconnect and reconnect
  const handleBotControl = async (botId, action) => {
	try {
	  setProcessingBotId(botId);
	  setProcessingAction(action);
	  const token = localStorage.getItem('authToken');
	  
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/bots/${botId}/control`, {
		method: 'POST',
		headers: {
		  'Authorization': `Bearer ${token}`,
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({ action })
	  });
	
	  const data = await response.json();
	  
	  if (!response.ok) {
		throw new Error(data.error || `${action} failed`);
	  }
	  
	  // Show success message or update UI as needed
	  setError('');
	  
	  // Refresh bots data to reflect updated status
	  fetchBots();
	  
	} catch (err) {
	  console.error(`Error ${action} bot:`, err);
	  setError(`Error: ${err.message}`);
	} finally {
	  setProcessingBotId(null);
	  setProcessingAction(null);
	}
  };

  // Logout handler
  const handleLogout = async () => {
	const token = localStorage.getItem('authToken');
	try {
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
		method: 'POST',
		headers: {
		  'Authorization': `Bearer ${token}`,
		  'Content-Type': 'application/json'
		}
	  });

	  if (!response.ok) {
		throw new Error('Logout failed');
	  }

	  localStorage.removeItem('authToken');
	  window.location.href = '/login';
	} catch (err) {
	  console.error('Error logging out:', err);
	  setError('Error logging out. Please try again.');
	}
  };

  // Filter menu handlers
  const handleMenuOpen = (event) => {
	setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
	setAnchorEl(null);
  };

  const handleFilterChange = (filter) => {
	setSelectedFilter(filter);
	handleMenuClose();
  };

  return (
	<Box className="dashboard-container">
	  {/* Header */}
	  <Box
		className="dashboard-header"
		sx={{
		  p: { xs: 2, md: 3 },
		  display: 'flex',
		  justifyContent: 'space-between',
		  alignItems: 'center',
		  flexDirection: { xs: 'column', sm: 'row' },
		  gap: { xs: 2, sm: 0 }
		}}
	  >
		<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'white' }}>
		  <Typography variant="h5" component="h1">
			Agent Management Dashboard
		  </Typography>
		</Box>

		<Box
		  sx={{
			display: 'flex',
			gap: 1,
			width: { xs: '100%', sm: 'auto' },
			justifyContent: { xs: 'space-between', sm: 'flex-end' }
		  }}
		>
		  <Tooltip title="Logout" arrow>
			<Button
			  variant="outlined"
			  color="inherit"
			  startIcon={<Logout />}
			  onClick={handleLogout}
			  className="header-action-button"
			  size="small"
			>
			  Logout
			</Button>
		  </Tooltip>
		</Box>
	  </Box>

	  {/* Search and Filters */}
	  <Box
		className="search-container"
		sx={{
		  display: 'flex',
		  flexDirection: { xs: 'column', md: 'row' },
		  gap: 2,
		  alignItems: { md: 'center' },
		  justifyContent: 'space-between'
		}}
	  >
		<Box sx={{ flex: 1 }}>
		  <TextField
			fullWidth
			variant="outlined"
			placeholder="Search agents by name, phone, or status..."
			value={searchQuery}
			onChange={(e) => setSearchQuery(e.target.value)}
			InputProps={{
			  startAdornment: (
				<InputAdornment position="start">
				  <Search color="action" />
				</InputAdornment>
			  )
			}}
			size="small"
			className="search-field"
		  />
		</Box>

		<Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
		  <Button
			variant="outlined"
			startIcon={<FilterList />}
			size="small"
			onClick={handleMenuOpen}
			sx={{
			  color: '#555',
			  borderColor: '#ccc',
			  '&:hover': { borderColor: '#999' }
			}}
		  >
			{selectedFilter === 'all'
			  ? 'All Status'
			  : `${selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1)} Only`}
		  </Button>

		  <Menu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleMenuClose}
			TransitionComponent={Fade}
		  >
			<MenuItem onClick={() => handleFilterChange('all')} selected={selectedFilter === 'all'}>
			  All Status
			</MenuItem>
			<MenuItem onClick={() => handleFilterChange('active')} selected={selectedFilter === 'active'}>
			  Active Only
			</MenuItem>
			<MenuItem onClick={() => handleFilterChange('idle')} selected={selectedFilter === 'idle'}>
			  Idle Only
			</MenuItem>
		  </Menu>

		  <Tooltip title="Export data" arrow>
			<Button variant="outlined" size="small" className="header-action-button">
			  <GetApp fontSize="small" />
			</Button>
		  </Tooltip>
		</Box>
	  </Box>

	  {/* Content */}
	  <Container maxWidth="xl" sx={{ py: 3, flex: 1 }}>
		<Card className="dashboard-card">
		  {loading ? (
			<Box className="loading-container">
			  <CircularProgress size={40} thickness={4} />
			  <Typography sx={{ mt: 2, color: '#555' }}>Loading agents...</Typography>
			</Box>
		  ) : error ? (
			<Box sx={{ p: 3 }}>
			  <Alert
				severity="error"
				action={
				  <Button color="inherit" size="small" onClick={fetchBots}>
					Retry
				  </Button>
				}
			  >
				{error}
			  </Alert>
			</Box>
		  ) : (
			<Paper elevation={0} className="improved-table-container">
			  <TableContainer className="table-container">
				<Table>
				  <TableHead>
					<TableRow>
					  <TableCell width="12%" className="table-header">Status</TableCell>
					  <TableCell
						onClick={() => handleSort('bot_name')}
						className="sortable-column table-header"
						width="22%"
					  >
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
						  Agent Name
						  {sortField === 'bot_name' &&
							(sortDirection === 'asc' ? (
							  <ArrowUpward fontSize="small" className="sort-indicator" />
							) : (
							  <ArrowDownward fontSize="small" className="sort-indicator" />
							))}
						</Box>
					  </TableCell>
					  <TableCell
						onClick={() => handleSort('bot_phone')}
						className="sortable-column table-header"
						width="16%"
					  >
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
						  Phone Number
						  {sortField === 'bot_phone' &&
							(sortDirection === 'asc' ? (
							  <ArrowUpward fontSize="small" className="sort-indicator" />
							) : (
							  <ArrowDownward fontSize="small" className="sort-indicator" />
							))}
						</Box>
					  </TableCell>
					  <TableCell
						onClick={() => handleSort('weight')}
						className="sortable-column table-header"
						width="14%"
					  >
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
						  Weight
						  {sortField === 'weight' &&
							(sortDirection === 'asc' ? (
							  <ArrowUpward fontSize="small" className="sort-indicator" />
							) : (
							  <ArrowDownward fontSize="small" className="sort-indicator" />
							))}
						</Box>
					  </TableCell>
					  <TableCell width="16%" className="table-header">Management Room</TableCell>
					  <TableCell align="right" width="20%" className="table-header">
						Actions
					  </TableCell>
					</TableRow>
				  </TableHead>
				  <TableBody>
					{filteredAndSortedBots.length > 0 ? (
					  filteredAndSortedBots.map((bot, index) => (
						<TableRow key={bot.id || index} hover className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
						  <TableCell>{getStatusChip(bot.status)}</TableCell>
						  <TableCell>
							<Typography variant="body2" fontWeight={500} className="agent-name">
							  {bot.bot_name || 'Unnamed Agent'}
							</Typography>
						  </TableCell>
						  <TableCell>{bot.bot_phone || 'N/A'}</TableCell>
						  <TableCell>
							<Box className="weight-container">
							  <div className="weight-number">{bot.weight || 0}</div>
							  {totalWeight > 0 && (
								<div className="weight-percentage">
								  {((bot.weight || 0) / totalWeight * 100).toFixed(1)}%
								</div>
							  )}
							</Box>
						  </TableCell>
						  <TableCell>{bot.management_room || 'N/A'}</TableCell>
						  <TableCell align="right">
							<Box className="action-button-grid">
							  <Box className="grid-row">
								<Tooltip title="Edit agent" arrow placement="top">
								  <Button
									variant="contained"
									size="small"
									startIcon={<Edit />}
									className="edit-button"
									onClick={() => openEditModal(bot)}
								  >
									EDIT
								  </Button>
								</Tooltip>
								
								<Tooltip title="Connect" arrow placement="top">
								  <Button
									variant="outlined"
									size="small"
									startIcon={<ConnectWithoutContact />}
									className="connect-button"
									onClick={() => handleConnectBot(bot.id)}
									disabled={processingBotId === bot.id || connectingBotId === bot.id}
								  >
									{connectingBotId === bot.id ? (
									  <CircularProgress size={16} thickness={4} />
									) : 'Connect'}
								  </Button>
								</Tooltip>
							  </Box>
							  
							  <Box className="grid-row">
								<Tooltip title="Disconnect" arrow placement="top">
								  <Button
									variant="outlined"
									size="small"
									color="error"
									startIcon={<LinkOff />}
									className="disconnect-button"
									onClick={() => handleBotControl(bot.id, 'disconnect')}
									disabled={processingBotId === bot.id}
								  >
									{processingBotId === bot.id && processingAction === 'disconnect' ? (
									  <CircularProgress size={16} thickness={4} />
									) : 'Disconnect'}
								  </Button>
								</Tooltip>
								
								<Tooltip title="Reconnect" arrow placement="top">
								  <Button
									variant="outlined"
									size="small"
									color="secondary"
									startIcon={<Refresh />}
									className="reconnect-button"
									onClick={() => handleBotControl(bot.id, 'reconnect')}
									disabled={processingBotId === bot.id}
								  >
									{processingBotId === bot.id && processingAction === 'reconnect' ? (
									  <CircularProgress size={16} thickness={4} />
									) : 'Reconnect'}
								  </Button>
								</Tooltip>
							  </Box>
							</Box>
						  </TableCell>
						</TableRow>
					  ))
					) : (
					  <TableRow>
						<TableCell colSpan={6} className="empty-message">
						  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
							<FilterList sx={{ fontSize: 48, color: '#ccc', mb: 2 }} />
							<Typography variant="h6" gutterBottom>
							  No agents found
							</Typography>
							<Typography variant="body2" color="text.secondary">
							  {searchQuery
								? 'Try adjusting your search or filter criteria'
								: 'Add your first agent to get started'}
							</Typography>
							{!searchQuery && (
							  <Button
								variant="contained"
								startIcon={<Add />}
								sx={{ mt: 2 }}
								onClick={() => console.log('Add agent clicked')}
							  >
								Add Agent
							  </Button>
							)}
						  </Box>
						</TableCell>
					  </TableRow>
					)}
				  </TableBody>
				</Table>
			  </TableContainer>
			</Paper>
		  )}
		</Card>
	  </Container>

	  {/* Improved Edit Modal */}
	  <Dialog 
		open={editModalOpen} 
		onClose={closeEditModal}
		PaperProps={{
		  className: "edit-modal"
		}}
		maxWidth="sm"
		fullWidth
	  >
		<DialogTitle className="edit-modal-title">
		  <Typography variant="h6" component="div">
			Edit Agent
		  </Typography>
		  <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 0.5 }}>
			{editingBot?.bot_name || 'Unnamed Agent'}
		  </Typography>
		</DialogTitle>
		<Divider />
		<DialogContent className="edit-modal-content">
		  <Box sx={{ mb: 3, mt: 1 }}>
			<FormControl fullWidth variant="outlined" margin="normal">
			  <InputLabel id="status-label">Status</InputLabel>
			  <Select
				labelId="status-label"
				value={editedStatus}
				onChange={(e) => setEditedStatus(e.target.value)}
				label="Status"
			  >
				<MenuItem value="active">
				  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<CheckCircle fontSize="small" sx={{ color: 'var(--success-main)' }} />
					<span>Active</span>
				  </Box>
				</MenuItem>
				<MenuItem value="idle">
				  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Warning fontSize="small" sx={{ color: 'var(--warning-main)' }} />
					<span>Idle</span>
				  </Box>
				</MenuItem>
				<MenuItem value="disconnected">
				  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<LinkOff fontSize="small" sx={{ color: 'var(--error-main)' }} />
					<span>Disconnected</span>
				  </Box>
				</MenuItem>
			  </Select>
			</FormControl>
			<TextField
			  fullWidth
			  margin="normal"
			  label="Phone Number"
			  variant="outlined"
			  value={editedPhone}
			  onChange={(e) => setEditedPhone(e.target.value)}
			  placeholder="Enter phone number"
			  InputLabelProps={{
				shrink: true,
			  }}
			/>
			
			<TextField
			  fullWidth
			  margin="normal"
			  label="Weight"
			  type="number"
			  variant="outlined"
			  value={editedWeight}
			  onChange={(e) => setEditedWeight(e.target.value)}
			  placeholder="Enter weight value"
			  InputLabelProps={{
				shrink: true,
			  }}
			  helperText="Higher weight values will receive more traffic"
			/>
		  </Box>
		</DialogContent>
		<Divider />
		<DialogActions className="edit-modal-actions">
		  <Button 
			onClick={closeEditModal} 
			variant="outlined"
			className="cancel-button"
		  >
			Cancel
		  </Button>
		  <Button 
			variant="contained" 
			onClick={handleUpdateBot}
			className="save-button"
		  >
			Save Changes
		  </Button>
		</DialogActions>
	  </Dialog>

	  {/* Footer */}
	  <Box className="footer-container">
		<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
		  <Typography variant="body2" className="count-text">
			Showing {filteredAndSortedBots.length} of {bots.length} agents
		  </Typography>
		  <Typography variant="body2" color="text.secondary">
			© {new Date().getFullYear()} Nokt, Inc. All rights reserved.
		  </Typography>
		</Box>
	  </Box>

	  {/* Connection Modal */}
	  <ConnectionModal
		open={connectionModalOpen}
		onClose={() => {
		  setConnectionModalOpen(false);
		  setConnectionRoomId(null);
		  setConnectionPhone('');
		  fetchBots();
		}}
		roomId={connectionRoomId}
		phone={connectionPhone}
	  />
	</Box>
  );
};

export default Dashboard;