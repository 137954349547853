import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../UserContext';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton
} from '@mui/material';
import {
  LockOutlined,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import noktLogo from './noktlogo.svg';
import './Registration.css';

function Registration() {
  const { loginUser } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
	setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
	e.preventDefault();
	setLoading(true);
	setError('');

	const email = e.target.email.value;
	const password = e.target.password.value;

	try {
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({ username: email, password })
	  });

	  if (response.ok) {
		const responseData = await response.json();
		const token = responseData.token;
		localStorage.setItem('authToken', token);
		loginUser();
		navigate('/dashboard');
	  } else {
		const errorData = await response.json();
		setError(errorData.message || 'Invalid credentials. Please try again.');
	  }
	} catch (error) {
	  console.error('Error during login:', error);
	  setError('Network error. Please check your connection and try again.');
	} finally {
	  setLoading(false);
	}
  };

  const handleForgotPassword = () => {
	window.location.href = 'https://www.nokt.io';
  };

  return (
	<Box
	  className="registration-container"
	  sx={{
		minHeight: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#f5f7fa',
		p: 2
	  }}
	>
	  <Container maxWidth="md">
		{/* Main paper containing the gradient (left) + form (right) */}
		<Paper
		  elevation={1}
		  sx={{
			overflow: 'hidden',
			borderRadius: 2,
			boxShadow: '0 10px 30px rgba(0, 0, 0, 0.08)'
		  }}
		>
		  <Grid container sx={{ minHeight: { xs: 'auto', md: '60vh' } }}>
			{/* Left (gradient) side */}
			<Grid
			  item
			  xs={12}
			  sm={4}
			  sx={{
				background: 'linear-gradient(135deg, #24366e 0%, #3a5cbf 100%)',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				p: 4
			  }}
			>
			  <Box
				component="img"
				src={noktLogo}
				alt="Nokt Logo"
				sx={{
				  width: '60%',
				  maxWidth: 140,
				  height: 'auto',
				  filter: 'brightness(0) invert(1)'
				}}
			  />
			  <Typography
				variant="h6"
				sx={{
				  color: 'white',
				  mt: 1,
				  textAlign: 'center',
				  fontFamily: "'Inter', sans-serif"
				}}
			  >
				Agent Management Dashboard
			  </Typography>
			</Grid>

			{/* Right (form) side */}
			<Grid
			  item
			  xs={12}
			  sm={8}
			  sx={{
				py: 6,
				px: { xs: 3, sm: 6 },
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center'
			  }}
			>
			  <Box
				sx={{
				  width: 72,
				  height: 72,
				  borderRadius: '50%',
				  background:
					'linear-gradient(135deg, #24366e 0%, #3a5cbf 100%)',
				  display: 'flex',
				  alignItems: 'center',
				  justifyContent: 'center',
				  mb: 3
				}}
			  >
				<LockOutlined sx={{ color: 'white', fontSize: 30 }} />
			  </Box>

			  <Typography
				variant="h4"
				component="h1"
				align="center"
				sx={{
				  mb: 5,
				  fontFamily: "'Inter', sans-serif",
				  color: '#24366e',
				  fontSize: { xs: '1.75rem', sm: '2rem' }
				}}
			  >
				Sign In
			  </Typography>

			  <Box
				component="form"
				onSubmit={handleLogin}
				sx={{
				  display: 'flex',
				  flexDirection: 'column',
				  gap: 3,
				  width: '100%',
				  maxWidth: 450
				}}
			  >
				{/* Email Field */}
				<Box>
				  <Typography
					component="label"
					htmlFor="email"
					sx={{
					  mb: 1,
					  fontWeight: 500,
					  display: 'block',
					  color: '#344054',
					  fontSize: '0.875rem'
					}}
				  >
					Email Address *
				  </Typography>
				  <TextField
					id="email"
					name="email"
					type="email"
					variant="outlined"
					fullWidth
					required
					autoFocus
					placeholder="Enter your email"
					InputProps={{
					  sx: { height: 56 }
					}}
				  />
				</Box>

				{/* Password Field */}
				<Box>
				  <Typography
					component="label"
					htmlFor="password"
					sx={{
					  mb: 1,
					  fontWeight: 500,
					  display: 'block',
					  color: '#344054',
					  fontSize: '0.875rem'
					}}
				  >
					Password *
				  </Typography>
				  <TextField
					id="password"
					name="password"
					type={showPassword ? 'text' : 'password'}
					variant="outlined"
					fullWidth
					required
					placeholder="Enter your password"
					InputProps={{
					  endAdornment: (
						<InputAdornment position="end">
						  <IconButton
							aria-label="toggle password visibility"
							onClick={handleTogglePasswordVisibility}
							edge="end"
						  >
							{showPassword ? <VisibilityOff /> : <Visibility />}
						  </IconButton>
						</InputAdornment>
					  ),
					  sx: { height: 56 }
					}}
				  />
				</Box>

				{/* Sign In Button */}
				<Button
				  type="submit"
				  variant="contained"
				  fullWidth
				  disabled={loading}
				  sx={{
					background:
					  'linear-gradient(135deg, #24366e 0%, #3a5cbf 100%)',
					py: 1.5,
					height: 56,
					fontSize: '1rem',
					textTransform: 'none',
					fontFamily: "'Inter', sans-serif"
				  }}
				>
				  {loading ? (
					<CircularProgress size={24} color="inherit" />
				  ) : (
					'Sign In'
				  )}
				</Button>

				<Button
				  onClick={handleForgotPassword}
				  variant="text"
				  sx={{
					color: '#667085',
					textTransform: 'none',
					fontFamily: "'Inter', sans-serif",
					alignSelf: 'center',
					fontSize: '0.875rem'
				  }}
				>
				  Forgot Password?
				</Button>
			  </Box>
			</Grid>
		  </Grid>
		</Paper>

		<Typography
		  variant="body2"
		  align="center"
		  sx={{ mt: 3, color: '#6b7c99' }}
		>
		  © {new Date().getFullYear()} Nokt, Inc. All rights reserved.
		</Typography>
	  </Container>

	  {/* Error Snackbar */}
	  <Snackbar
		open={!!error}
		autoHideDuration={6000}
		onClose={() => setError('')}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
	  >
		<Alert
		  onClose={() => setError('')}
		  severity="error"
		  variant="filled"
		  sx={{ width: '100%' }}
		>
		  {error}
		</Alert>
	  </Snackbar>
	</Box>
  );
}

export default Registration;
